<template>
  <div>
      <div class="bg-gray-900" style="height: 150px;">
        <div class="container py-2 block">
            <div class="flex w-full justify-between sm:p-3 md:p-0 p-3">
                <h5 class="text-white sm:text-xl md:text-2xl text-xl font-bold my-auto">Success Payment</h5>
                <div class="my-auto text-white" @click="backHome()">
                    <b-button type="is-primary">Go to Home</b-button>
                </div>
            </div>
            <div class="relative" style="top: 120px;">
                <span class="font-bold ml-2">Payment method</span>
                <div class="border border-collapse rounded-lg p-4 mb-4">
                    <div>
                        <div class="flex">
                            <img :src="paymentData && paymentData.PaymentMethodObj.Image" :width="isMobile ? '24%' : '14% '" alt="">
                            <div>
                                <span class="ml-4">Bank Account</span>
                                <span class="ml-4 block font-bold">{{ paymentData.PaymentMethodObj.Name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border border-collapse rounded-lg p-4">
                    <div>
                        <span class="font-bold text-lg">Virtual account</span>
                        <hr/>
                    </div>
                    <!-- <div class="block">
                        <div class="flex justify-between">
                            <span class="font-bold">Contact Name</span>
                            <span>{{ paymentData.ContactName }}</span>
                        </div>
                        <hr/>
                    </div>
                    <div class="block">
                        <div class="flex justify-between">
                            <span class="font-bold">Contact Phone</span>
                            <span>{{ paymentData.ContactPhone }}</span>
                        </div>
                        <hr/>
                    </div> -->
                    <div>
                        <div class="flex justify-between">
                            <span class="font-bold">VA No</span>
                            <div class="ml-4 font-bold text-grey cursor-pointer hover:text-primary" @click="copy()">
                                <span>{{ paymentData.CodeVABCA }}</span>
                                <span class="ml-4 font-bold text-blue-300 cursor-pointer hover:text-blue-600">Copy</span>
                                <input id="cb" type="text" hidden>
                            </div>
                        </div>
                        <hr/>
                    </div>
                    <div>
                        <div class="flex justify-between">
                            <span class="font-bold">Pay Before</span>
                            <div><span>{{ paymentData.DateExpiry }}</span></div>
                        </div>
                        <hr/>
                    </div>
                    <div class="block">
                        <div class="flex justify-between">
                            <span class="font-bold">Total Amount</span>
                            <span class="ml-4 font-bold text-red-400">Rp. {{ formatNumber(paymentData.TotalAmount) }}</span>
                        </div>
                        <hr/>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            isMobile: window.innerWidth < 767,
            paymentHide: true,
            paymentData: {},
            file: '',
            fileName: '',
            valueno: 12123901283901283,
            back3: JSON.parse(localStorage.getItem('backHome3'))
        }
    },
    methods: {
        copy () {
            var cb = document.getElementById('cb')
                cb.value = this.paymentData.CodeVABCA
                cb.style.display = 'block'
                cb.select()
                document.execCommand('copy')
                cb.style.display = 'none'
        },
        backHome () {
            window.open('http://elitecorp.id/', '_self')
        },
        uploadFile (e) {
            this.file = e.target.files[0]
            this.fileName = e.target.files[0].name
        },
        async paid () {
            localStorage.setItem('backHome3', JSON.stringify(true))
            try {
                const fd = new FormData()
                fd.append('File', this.file)
                var id = this.$route.query.id
                var data = { fd, id }

                var response = await this.$baseApi({
                    method: 'POST',
                    url: '/booking/' + this.$route.query.Oid + '/verify',
                    data: data.fd
                })
                if (response) {
                    this.$router.push({
                        path: '/item/success'
                    })
                }
            } catch (err) {
                console.log('err', err)
            }
        },
        async init () {
            if (this.back3) {
                return this.$router.push('/product?Oid=' + JSON.parse(localStorage.getItem('Oid')))
            } else {
                try {
                    this.paymentData = await this.$baseApi({
                        method: 'GET',
                        url: '/cart/' + this.$route.query.Oid
                    })
                    this.paymentHide = false
                    setTimeout(() => {
                        this.paymentHide = true
                    }, 100)
                } catch (err) {
                    console.log('err', err)
                }
            }
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style>

</style>
